<template>
    <details class="dropdown" :id="detailId" @mouseleave="leave" :open="isOpen">
        <summary @mouseover="hover">
            <slot name="summary"></slot>
        </summary>
        <div class="details-container" @click="handleClick">
            <div class="details">
                <slot></slot>
            </div>
        </div>
    </details>
</template>
<script>
import { useId } from 'vue';

export default {
    props: {
        hasSubMenu: Boolean,
    },
    data() {
        return {
            detailId: useId(),
            isOpen: false,
        }
    },
    mounted() {
        if (this.hasSubMenu) {
            this.$bus.on('close-all-menus', this.closeMe);
        }
    },
    methods: {
        leave() {
            this.isOpen = false;
        },
        hover() {
            this.isOpen = true
        },
        closeMe() {
            this.isOpen = false;
        },
        handleClick(event) {
            event.stopPropagation();
            this.isOpen = false;
            this.$bus.emit('close-all-menus')
        },
    }
}
</script>
<style scoped>
details.dropdown {
    display: block;
}

summary::-webkit-details-marker,
summary::marker {
    display: none;
}

summary {
    display: flex;
    align-items: center;
    cursor: pointer;
}

details[open] div:not(.details-container),
details[open]:not(.sub-menu)>summary :deep(div) {
    background: var(--lite-gray-white, #FFFFFF) !important;
}

details.dropdown div.details {
    background-color: var(--lite-gray-white, #FFFFFF);
    min-width: -webkit-fill-available;
    border-radius: .8rem;
    margin-top: .5rem;
}
</style>