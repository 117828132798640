export const topLevelMenu = [
    {
        index: 1,
        name: 'Live map',
        link: '/map',
        tags: 'Report,People,Fleet',
        icon: 'location/map'
    },
    {
        index: 3,
        name: 'Users',
        link: '/users',
        tags: 'Report,People,Fleet',
        icon: 'people/group'
    },
    {
        index: 4,
        name: 'Booking log',
        link: '/bookinglog',
        tags: 'Report,People,Fleet',
        icon: 'car/car'
    },
]

export const genericTags = ['Report', 'People', 'Fleet'];