import { createStore } from 'vuex';
import { getAuth, setAuth, setIsAdmin } from '@/utils/functions';
import { genericTags, topLevelMenu } from '@/utils/static';
import axios from 'axios';
import ENV from '@/env';
import bus from '@/eventBus';

const store = createStore({
    state() {
        return {
            isLoggedIn: false, // Initial state is that the user is not logged in
            isFramed: false,
            isAdmin: false,
            onboarding: null,
            nextButtonEnabled: false,
            isMobile: window.innerWidth <= 768,
            widthBreakPoint: 768,
            isTokenExpired: false,
            notifications: [],
            searchCriteria: null,
            profile: null,
            refreshAttempted: false,
            menu: null,
        };
    },
    mutations: {
        setRefreshAttempted(state, value) {
            state.refreshAttempted = value;
        },
        setMenu(state, value) {
            state.menu = value;
        },
        setIsFramed(state, value) {
            state.isFramed = value;
        },
        setIsAdmin(state, value) {
            state.isAdmin = value;
            setIsAdmin(value);
        },
        setLoggedIn(state, value) {
            state.isLoggedIn = value;
        },
        setOnboarding(state, { key, value }) {
            if (state.onboarding === null) state.onboarding = {};
            state.onboarding[key] = value;
        },
        setProfile(state, { key, value }) {
            if (state.profile === null) state.profile = {};
            state.profile[key] = value;
        },
        setSearchCriteria(state, { key, value }) {
            if (state.searchCriteria === null) state.searchCriteria = {};
            if (value === null) {
                delete state.searchCriteria[key];
            } else {
                state.searchCriteria[key] = value;
            }
        },
        clearSearchCriteria(state) {
            sessionStorage.removeItem('searchParams');
            state.searchCriteria = null;
        },
        setNextButtonEnabled(state, value) {
            state.nextButtonEnabled = value;
        },
        setIsMobile(state, payload) {
            state.isMobile = payload;
        },
        setWidthBreakPoint(state, payload) {
            state.widthBreakPoint = payload;
        },
        setTokenExpired(state, value) {
            state.isTokenExpired = value;
        },
        pushNotification(state, value) {
            state.notifications.push(value);
        },
        removeNotification(state, index) {
            state.notifications.splice(index, 1);
        },
        clearNotifications(state) {
            state.notifications = [];
        },
        logout(state) {
            // Save the properties you don't want to clear
            const isMobile = state.isMobile;
            const widthBreakPoint = state.widthBreakPoint;

            // Reset all other state properties
            Object.assign(state, {
                isLoggedIn: false,
                onboarding: null,
                nextButtonEnabled: false,
                isTokenExpired: false,
                notifications: [],
                searchCriteria: null,
                profile: null,
                refreshAttempted: false,
            });

            // Restore the values you want to keep
            state.isMobile = isMobile;
            state.widthBreakPoint = widthBreakPoint;
        },
        loadNotifications(state) {
            let message = '';
            if (state.profile.licence_state.toLowerCase() !== 'valid') {
                message = (state.profile.licence_state.toLowerCase() === 'pending') ?
                    'Licence is pending validation. ' : 'Your licence is invalid. ';
                message += 'You are not able to book any cars before it is validated';
                state.notifications.push({ message, class: 'error' });
            }
        },
    },
    actions: {
        refreshToken({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                commit('setRefreshAttempted', true);
                axios.post(`${ENV.API_HOST}/auth/login`, {
                    refresh_token: sessionStorage.getItem('refToken'),
                }).then((response => {
                    setAuth(response.data);
                    dispatch('loadProfile').then(() => {
                        resolve(true);
                    }).catch(() => {
                        reject(new Error('Error loading profile'))
                    });
                })).catch(e => {
                    console.error('Invalid credentials, need to re-login: ', e);
                    dispatch('logout');
                    reject(new Error('Failed refreshing token'));
                })
            })
        },
        loadProfile({ commit, dispatch, state }) {
            return new Promise((resolve, reject) => {
                const accessToken = getAuth()
                axios.get(`${ENV.API_HOST}/user/details`, { headers: { Authorization: `Bearer ${accessToken}` } }).then(resp => {
                    for (const key in resp.data) {
                        commit('setProfile', { key, value: resp.data[key] });
                    }
                    if (resp.data?.access === 'fleet manager') {
                        commit('setIsAdmin', true);
                        dispatch('fetchMenu').catch(() => {
                            bus.emit('show-toast-message', {
                                text: 'There was an error fetching the menu.',
                                error: true,
                            })
                        })
                    } else {
                        commit('setIsAdmin', false);
                    }
                    // commit('loadNotifications');
                    commit('setLoggedIn', true);
                    resolve(true);
                }).catch(e => {
                    console.error('Error fetching profile details with current accessToken:', e);
                    if (!state.refreshAttempted) {
                        dispatch('refreshToken')
                            .then(() => { resolve(true) })
                            .catch(() => { reject(new Error('Attempted refresh but failed')) });
                    } else {
                        dispatch('logout');
                        reject(new Error('Fetching profile failed'));
                    }
                });
            })
        },
        fetchMenu({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${ENV.API_HOST}/admin/menus`, {
                    headers: { Authorization: `Bearer ${getAuth()}` }
                }).then(response => {
                    const allTags = {};
                    const menus = Object.keys(response.data).reduce((acc, key) => {
                        const keyTags = new Set();
                        const menu = [
                            ...(key === 'top_level') ? topLevelMenu : [],
                            ...response.data[key]
                        ].sort((a, b) => a.index - b.index);
                        acc[key] = menu.map(item => {
                            const tagTemplate = (key === 'top_level') ? genericTags : [];
                            if (item.name === 'Planner') item.icon = 'location/route';
                            const tags = item.tags ? item.tags.split(',').map(tag => tag.trim()) : tagTemplate;
                            tags.forEach(tag => keyTags.add(tag));
                            return {
                                ...item,
                                tags
                            }
                        });
                        allTags[key] = Array.from(keyTags);
                        return acc;
                    }, {});
                    menus.allTags = allTags;
                    if (response.data) commit('setMenu', menus);
                    resolve(true)
                }).catch(error => {
                    console.error(error);
                    reject(new Error('Failed fetching admin menus'))
                })
            });
        },
        login({ commit, dispatch }, { email_address, password }) {
            return new Promise((resolve, reject) => {
                axios.post(`${ENV.API_HOST}/auth/login`, {
                    email_address, password
                }).then(response => {
                    setAuth(response.data)
                    dispatch('loadProfile').then(() => {
                        commit('setLoggedIn', true);
                        resolve(true);
                    }).catch(() => {
                        dispatch('logout');
                        reject(new Error('Login failed'));
                    });
                }).catch(() => {
                    dispatch('logout');
                    reject(new Error('Login failed'));
                });
            })
        },
        logout({ commit }) {
            sessionStorage.clear();
            localStorage.clear();
            commit('logout');
        },
        updateIsMobile({ commit }) {
            commit('setIsMobile', window.innerWidth <= this.state.widthBreakPoint);
        },
    }
});

export default store;
