<template>
    <footer class="footer is-left" v-show="false">
        <p class="b3 reg">&copy;2024 {{ appVersion }}</p>
    </footer>
</template>
<script>
export default {
    data() {
        return {
            appVersion: process.env.VUE_APP_VERSION || 'sandbox'
        }
    }
}
</script>
<style scoped>
footer.footer {
    width: -webkit-fill-available;
    margin-top: auto;
}

p {
    margin: 0;
}
</style>