<template>
    <div v-if="visible" v-show="!dismissed" class="update-notification">
        <RowDiv class="is-right">
            <SelectableButton class="xsmall dark borderless" @click="dismissed = true">
                <SvgIcon name="icon/close" />
            </SelectableButton>
        </RowDiv>
        <RowDiv class="is-center">
            <p>A new version is available. Please refresh the page.</p>
        </RowDiv>
        <SelectableButton class="small" @click="refreshPage">
            <div class="bu1 semi">Refresh</div>
        </SelectableButton>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            dismissed: false
        };
    },
    mounted() {
        this.$bus.on('new-version-available', this.showNotification);
    },
    methods: {
        showNotification() {
            this.visible = true;
        },
        refreshPage() {
            window.location.reload();
        }
    }
};
</script>
<style scoped>
.update-notification {
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    background: var(--bg-secondary-color, #042B2F);
    color: var(--lite-gray-white, #FFFFFF);
    text-align: center;
    padding: 1rem 4rem 2rem;
    border-radius: 1rem;
}
</style>