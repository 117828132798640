<template>
    <div :class="'col' + (cols ? '-' : '') + cols"><slot /></div>
</template>
<script>
export default {
    props: {
        cols: {
            type: String,
            default: ''
        }
    }
}
</script>