<template>
    <div :class="['screen', { 'onboarding-screen': this.$route.path.includes('onboarding') }]">
        <HeaderView />
        <div v-if="(this.notifications.length > 0)" class="container">
            <RowDiv  v-for="(notification, index) in this.notifications" :key="index">
                <div class="col">
                    <HeaderAlert :extraClass="notification.class" @close="handleCloseAlert(index)">
                        {{ notification.message }}
                    </HeaderAlert>
                </div>
            </RowDiv>
        </div>
        <router-view>
        </router-view>
        <div class="container" v-if="isLoggedIn && !isFramed">
            <RowDiv >
                <div class="col is-center">
                    <SelectableButton class="light small borderless" @click="goToFeedback">
                        <div class="bu1 semi">Tell us what you think!</div>
                    </SelectableButton>
                </div>
            </RowDiv>
        </div>
        <CopyrightFooter />
        <NewVersionToast />
        <GenericToast />
    </div>
</template>
<script>
import CopyrightFooter from '@/components/common/CopyrightFooter.vue';
import GenericToast from '@/components/common/GenericToast.vue';
import HeaderView from '@/components/common/HeaderView.vue';
import HeaderAlert from '@/components/common/HeaderAlert.vue';
import NewVersionToast from '@/components/common/NewVersionToast.vue';
import '@vuepic/vue-datepicker/dist/main.css'
import { mapState, mapMutations, mapActions } from 'vuex';
import { getAuth } from '@/utils/functions';
export default {
    data() {
        return {
            showAlert: false,
            currentMessage: 0,
            messages: [],
        }
    },
    components: {
        CopyrightFooter,
        GenericToast,
        HeaderAlert,
        HeaderView,
        NewVersionToast,
    },
    computed: {
        ...mapState(['notifications', 'isLoggedIn', 'isFramed', 'isAdmin']),
    },
    mounted() {
        const auth = getAuth();
        if (!!auth && !this.isLoggedIn) {
            this.loadProfile(auth).then(() => { 
                if (this.isAdmin) this.fetchMenu().catch(error => { 
                    console.error(error);
                })
            }).catch(() => {
                this.$router.replace({ name: 'Login' })
            });
        }
    },
    methods: {
        handleCloseAlert(index) {
            this.removeNotification(index);
        },
        goToFeedback() { 
            sessionStorage.setItem('feedback', 'feedback');
            this.$router.push({ name: 'Profile' });
        },
        ...mapMutations(['clearNotifications', 'removeNotification']),
        ...mapActions(['loadProfile', 'fetchMenu']),
    },
};
</script>