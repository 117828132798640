<template>
    <div class="icon">
        <div v-html="svgContent" class="svg-container icon-absolute"></div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        className: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            svgContent: '',
        };
    },
    watch: {
        async name(newVal) {
            await this.loadSVG(newVal);
        },
    },
    async created() {
        await this.loadSVG(this.name);
    },
    methods: {
        async loadSVG(name) {
            try {
                const svgPath = require(`@/assets/icons/${name}.svg`);
                const response = await fetch(svgPath);
                this.svgContent = await response.text();
            } catch (error) {
                console.error(`Failed to load icon: ${name}`, error);
            }
        }
    }
};
</script>
<style scoped>
.svg-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg-container :deep(svg) {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
}

.icon-absolute {
    height: auto;
    position: absolute;
    left: 0rem;
    top: 0rem;
    overflow: visible;
}

</style>