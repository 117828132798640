import { createRouter, createWebHistory } from 'vue-router';
import { getAuth, getIsAdmin } from '@/utils/functions';

const routes = [
    // Auth required
    { path: '/', redirect: '/home' },
    { path: '/home', name: 'Home', component: () => import('@/views/HomeView.vue') },
    { path: '/contact', name: 'Contact', component: () => import('@/views/ContactView.vue') },
    { path: '/profile', name: 'Profile', component: () => import('@/views/ProfileView.vue') },
    { path: '/search', name: 'Search Booking', component: () => import('@/views/SearchBooking.vue') },
    { path: '/booking', name: 'Configure Booking', component: () => import('@/views/CustomBooking.vue') },
    {
        path: '/booking/view/:id',
        name: 'View Booking',
        component: () => import('@/views/booking/ViewBooking.vue'),
        props: route => ({ id: route.params.id })
    },
    {
        path: '/booking/join/:id',
        name: 'Join Booking',
        component: () => import('@/views/booking/CarpoolBooking.vue'),
        props: route => ({ id: route.params.id })
    },
    { path: '/fleet/manager', name: 'Mobility Center', component: () => import('@/views/FleetManager.vue') },
    { path: '/fleet/insights', name: 'Insights', component: () => import('@/views/fleet-manager/InsightReports.vue') },
    { path: '/fleet/report', name: 'Reports', component: () => import('@/views/fleet-manager/UserReports.vue') },
    { path: '/fleet/insight/:index', name: 'Insight', component: () => import('@/views/fleet-manager/InsightsFrame.vue'), props: true },
    { path: '/fleet/reports/:page', name: 'Download Report', component: () => import('@/views/fleet-manager/reports/ReportView.vue'), props: true },
    { path: '/fleet/:framed+', name: 'Legacy', component: () => import('@/views/fleet-manager/FramedPage.vue'), props: true },
    // No auth
    { path: '/login', name: 'Login', component: () => import('@/views/LoginView.vue') },
    { path: '/reset', name: 'Reset Password', component: () => import('@/views/ResetPassword.vue') },
    {
        path: '/documents/:page',
        name: 'Document',
        component: () => import('@/views/DocumentView.vue'),
        props: route => ({ page: route.params.page })
    },
    { path: '/onboarding/join', name: 'Join', component: () => import('@/views/onboarding/JoinView.vue') },
    { path: '/onboarding/verify-email', name: 'Verify your Email', component: () => import('@/views/onboarding/VerifyEmail.vue') },
    // Token required
    { path: '/profile/verified', name: 'Email verification', component: () => import('@/views/profile/EmailVerify.vue') },
    { path: '/onboarding/verified', name: 'Email verified', component: () => import('@/views/onboarding/VerifiedView.vue') },
    { path: '/onboarding/welcome', name: 'Welcome', component: () => import('@/views/onboarding/WelcomeView.vue') },
    { path: '/onboarding/getting-to-know', name: 'Getting to know you', component: () => import('@/views/onboarding/KnowingYou.vue') },
    { path: '/onboarding/driver-details', name: 'Driver details', component: () => import('@/views/onboarding/DriverDetails.vue') },
    { path: '/onboarding/policies', name: 'Policies', component: () => import('@/views/onboarding/PoliciesView.vue') },
    { path: '/onboarding/cards', name: 'Cards - Business', component: () => import('@/views/onboarding/AddBusiness.vue') },
    { path: '/onboarding/add-cards', name: 'Add cards - Business', component: () => import('@/views/onboarding/CardsBusiness.vue') },
    { path: '/onboarding/personal-use', name: 'Personal usage', component: () => import('@/views/onboarding/PersonalUse.vue') },
    { path: '/onboarding/personal-card', name: 'Personal card', component: () => import('@/views/onboarding/CardsPersonal.vue') },
    { path: '/onboarding/personal-policy', name: 'Personal Policy', component: () => import('@/views/onboarding/PersonalPolicy.vue') },
    { path: '/onboarding/add-access-card', name: 'Add Access Card', component: () => import('@/views/onboarding/AddAccessCard.vue') },
    { path: '/onboarding/send-access-card', name: 'Send Access Card', component: () => import('@/views/onboarding/AccessCardDelivery.vue') },
    { path: '/onboarding/all-done', name: 'Almost done', component: () => import('@/views/onboarding/AllDone.vue') },
    { path: '/onboarding/congratulations', name: 'Congratulations', component: () => import('@/views/onboarding/CongratulationsView.vue') },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0)
    }
});

router.beforeEach((to, from, next) => {
    const auth = getAuth();
    const isAdmin = getIsAdmin();
    const titlePrefix = window.config.VUE_APP_TITLE_PREFIX || 'Zilch';
    document.title = titlePrefix + (to.name ? (' > ' + to.name) : '');
    if ((to.path === '/login') && auth) {
        next({ name: 'Home' });
    } else if ((to.path === '/login') ||
        (to.path === '/reset') ||
        (to.path === '/reset/') ||
        (to.path === '/profile/verified') ||
        (to.path === '/profile/verified/') ||
        to.path.includes('onboarding') ||
        to.path.includes('documents')
    ) {
        next();
    } else if (!auth) {
        next({ path: '/login' })
    } else {
        if (to.path.startsWith('/fleet')) {
            if (!isAdmin) next({ name: 'Home' })
            document.body.style.background = 'var(--lite-gray-grey-3)'; // Change background to new color
        } else {
            document.body.style.background = ''; // Reset to the default background
        }
        next()
    }
});

export default router;
