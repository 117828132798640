export function isValidDate(date) {
    const regex = /^(0[1-9]|[12][0-9]|3[01])[/-](0[1-9]|1[0-2])[/-](\d{4})$/;
    if (!regex.test(date)) {
        return false;
    }

    const parts = date.split(/\/|-/);
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    const dateObj = new Date(year, month - 1, day);
    return (
        dateObj.getFullYear() === year &&
        dateObj.getMonth() === month - 1 &&
        dateObj.getDate() === day
    );
}

export function isNumericString(str) {
    return /^\d+$/.test(str);
}

export function isMonYear(str) {
    return /\d{2}\/\d{2}/.test(str);
}

export function isValidCreditCardNumber(number) {
    const sanitizedNumber = number.replace(/[\s-]/g, ''); // Remove spaces and hyphens

    const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const mastercardRegex = /^5[1-5][0-9]{14}$/;
    const amexRegex = /^3[47][0-9]{13}$/;
    const dinersClubRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
    const discoverRegex = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
    const jcbRegex = /^(?:2131|1800|35\d{3})\d{11}$/;

    return visaRegex.test(sanitizedNumber) ||
        mastercardRegex.test(sanitizedNumber) ||
        amexRegex.test(sanitizedNumber) ||
        dinersClubRegex.test(sanitizedNumber) ||
        discoverRegex.test(sanitizedNumber) ||
        jcbRegex.test(sanitizedNumber);
}

export function isEmpty(value) {
    return value === undefined || value === null || value === '';
}

export function isRequired(value) {
    return !isEmpty(value);
}

export function isValidPaddedNumeric(value, params) {
    const pattern = new RegExp(`^\\d{${params.length}}$`);
    return pattern.test(value);
}

export function isValidEmail(value) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}

export function isLicence(value) {
    return /^[A-Za-z]{2}[0-9]{6}$/.test(value);
}

export function isPassword(value) {
    const pattern = new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-._!#%&,:;<>=@{}~\\$\\(\\)\\*\\+\\/\\\\\\?\\[\\]\\^\\|]).{8,}$");
    return pattern.test(value);
}
