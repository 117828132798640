<template>
    <div class="root">
        <div :class="['forms-small-fields-default', $attrs.class, className, { 'field-alert': isInvalid }]">
            <SvgIcon :name="iconSrc" v-if="iconSrc" class="icon" />
            <input @input="debounceInput" :class="['form-field-value', 'b2 reg', { 'typing': isTyping }]" :type="internalType"
                :value="internalValue" :placeholder="placeholder" v-bind="$attrs" @focus="handleFocus"
                @blur="handleBlur" :autocomplete="autoFill" @keydown.enter="handleEnter">
            <div>
                <SvgIcon name="feedback/warning-outline" class="alert-icons" v-if="isInvalid || !valid" />
            </div>
            <div v-if="(type === 'combo')">
                <SvgIcon name="icon/down" @click="(this.$bus.emit('open-dropdown'))" />
            </div>
            <div v-if="type === 'password'">
                <SvgIcon :name="passwordIcon" @click="togglePassword" />
            </div>
            <slot name="extra"></slot>
        </div>
        <div class="validation-message" v-if="isInvalid || !valid">{{ validationMessage || validityMessage }}</div>
    </div>
</template>
<script>
import { debounce } from '@/utils/debounce.js';
import * as validations from '@/utils/validation.js';
export default {
    inheritAttrs: false,
    props: {
        autoFill: {
            type: String,
            default: 'do-not-auto-fill'
        },
        className: {
            type: String,
            default: ''
        },
        iconSrc: {
            type: String,
            default: '',
        },
        modelValue: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        validation: {
            type: Array,
            default: () => [],
        },
        max: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        valid: {
            type: Boolean,
            default: true,
        },
        validityMessage: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            internalValue: this.modelValue,
            validationMessage: '',
            isInvalid: !this.valid,
            externalValue: this.value,
            isTyping: false,
            internalType: 'text',
            passwordIcon: 'icon/eye',
            debounceInput: null,
        };
    },
    created() { 
        this.debounceInput = debounce(this.handleInput, 500);
    },
    watch: {
        modelValue(newVal) {
            this.internalValue = newVal;
        },
        value(newVal) {
            this.externalValue = newVal;
        },
    },
    mounted() {
        this.internalType = (this.type === 'combo') ? 'text' : this.type;
        if (this.type === 'password') {
            this.passwordIcon = 'icon/hide'
        }
    },
    emits: ['update:modelValue', 'validation', 'focus', 'enter'],
    methods: {
        togglePassword() {
            this.internalType = (this.internalType === 'password') ? 'text' : 'password';
            this.passwordIcon = (this.internalType === 'password') ? 'icon/hide' : 'icon/eye';
        },
        handleFocus() {
            this.isTyping = true;
            this.$emit('focus');
        },
        handleEnter(event) { 
            this.handleInput(event);
            this.$emit('enter');
        },
        handleInput(event) {
            this.internalValue = event.target.value;
            this.$emit('update:modelValue', this.internalValue);
            this.validate();
        },
        handleBlur(event) { 
            this.isTyping = false;
            this.internalValue = event.target.value;
            this.$emit('update:modelValue', this.internalValue);
            this.validate();
        },
        validate() {
            const value = this.internalValue;
            this.validationMessage = '';
            this.isInvalid = false;

            // Check if "required" rule exists and value is empty
            const isRequired = this.validation.includes('required');
            if (!isRequired && value.trim() === '') {
                return; // Skip other validations
            }

            for (const rule of this.validation) {
                if (typeof rule === 'string') {
                    // Regular validation rule
                    const ruleValidator = this.getValidator(rule);
                    if (ruleValidator && !ruleValidator(value)) {
                        this.validationMessage = this.getValidationMessage(rule);
                        this.isInvalid = true;
                        break;
                    }
                } else if (typeof rule === 'object') {
                    // Custom validation rule with parameters
                    const { rule: customRule, ...params } = rule;
                    const ruleValidator = this.getValidator(customRule);
                    if (ruleValidator && !ruleValidator(value, params)) {
                        this.validationMessage = this.getValidationMessage(customRule, params);
                        this.isInvalid = true;
                        break;
                    }
                }
            }
            this.$emit('validation', this.validationMessage);
        },
        getValidator(rule) {
            switch (rule) {
                case 'required':
                    return validations.isRequired;
                case 'numeric':
                    return validations.isNumericString;
                case 'length':
                    return validations.isValidPaddedNumeric;
                case 'credit-card':
                    return validations.isValidCreditCardNumber;
                case 'mmyy':
                    return validations.isMonYear;
                case 'email':
                    return validations.isValidEmail;
                case 'licence':
                    return validations.isLicence;
                case 'password':
                    return validations.isPassword;
                // Add more cases for other validation rules as needed
                default:
                    return null;
            }
        },
        getValidationMessage(rule, params = null) {
            switch (rule) {
                case 'required':
                    return 'This field is required.';
                case 'numeric':
                    return 'This field must be numeric.';
                case 'length':
                    return 'This field must be exactly ' + params.length + ' characters long.';
                case 'date':
                    return 'Date must be valid in the format of "dd/mm/yyyy" or "dd-mm-yyyy"';
                case 'credit-card':
                    return 'This field must be in a valid credit card format';
                case 'mmyy':
                    return 'This field must be in a "mm/yy" format';
                case 'email':
                    return 'This field must be a valid email format';
                case 'licence':
                    return 'This field must be a valid licence format (YZ123456)';
                case 'password':
                    return 'This field must have 8 characters, contain an uppercase letter, a number, and special character ';
                // Add more cases for other validation rules as needed
                default:
                    return '';
            }
        },
    },
};
</script>
<style scoped>
.root {
    width: -webkit-fill-available;
}

.forms-small-fields-default {
    background: var(--color-grey, #FAFAFA);
    border-radius: 0.8rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    height: 3.6rem;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    overflow: hidden;
    align-self: stretch;
    border: 1px solid transparent;
}

.forms-small-fields-default:hover {
    border-color: var(--input-hover-color);
}

.form-field-value,
input.form-field-value {
    color: var(--dark-grey-grey-9-links, #5C6163);
    text-align: left;
    font-family: var(--regular-font-family, "DmSans-Regular", sans-serif);
    font-size: 1.6rem;
    font-weight: var(--regular-font-weight, 400);
    position: relative;
    flex: 1;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    align-self: stretch;
}

.form-field-value.typing,
input.form-field-value.typing {
    color: var(--font-color, #042B2F);
}

.forms-small-fields-default.white {
    background-color: var(--lite-gray-white, #FFFFFF);
}

.field-alert {
    border: 1px solid red;
}

.alert-icons {
    color: red;
}

.validation-message {
    text-align: left;
    font-size: var(--xsmall-font-size, 1rem);
    font-weight: var(--footers-footnote-1-font-weight, 300);
    color: red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

</style>